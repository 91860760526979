import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import CopyrightNote from '../components/CopyrightNote'

const NotFoundPage = () => (
  <Layout navVisible={false}>
    <div id="main">
      <section id="not-found" className="main">
        <span className="icon fa-times-circle-o"></span>
        <h2>We can't find the page you're looking for.</h2>
        <Link to="/" className="button">Back to Home</Link>
        <footer id="footer" className="major">
          <CopyrightNote />
        </footer>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
